.optimization-calc__container {
  background-color: var(--white-color);
  display: grid;
  z-index: 10;
}

.optimization-settings__edit-element-panel {
  & .checkbox-content {
    color: var(--primary-text-color)
  }
}

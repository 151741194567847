.main-page__container {
  min-width: 1080px;
  padding: 80px 0 20px 0;
  overflow: hidden;
}

.main-page-content__container {
  display: flex;
  flex-direction: column;
  width: 880px;
  margin: auto;
}

.project-list__projects{
  display: flex;
  flex-direction: column;
  max-width: 880px;
  min-width: 650px;
  width: 45.8%;
  padding: 80px 0 20px 20px;
  margin-left: 25.88%;
}

.project-list__projects-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
}

.project-list__projects-btn{
  display: flex;
  gap: 20px;
  height: 36px;
}

.project-list__my-projects-wrapper {
  width: 293px;
  height: 36px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.project-list__projects-title{
  color: var(--primary-text-color);
}

.project-list__projects-body {
  display: flex;
  flex-grow: 1;
  gap: 32px;
  flex-direction: column;
  padding-top: 32px;
}

.project-list__loading-message {
  color: var(--primary-text-color);
}

.hydraulic-settings__settings-row {
  position: relative;
  display: flex;
  width: 776px;
  flex-wrap: wrap;
  margin-bottom: 20px;
  column-gap: 16px;
  row-gap: 33px;
}

.hydraulic-settings__settings-item {
  width: 182px;

  &.year-item {
    margin-left: 26px;
  }
}

.hydraulic-settings__button {
  width: 140px;
}

.hydraulic-settings__loading-message {
  color: var(--primary-text-color);
}

.hydraulic-calc__calc-types {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 10px;
}
.hydraulic-settings__checkbox{
  display: inline-flex;
  gap: 8px;
}

.economic-results__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  margin-top: 32px;
}

.economic-results__item {
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 20px;
}

.economic-results__records {
  display: grid;
  grid-template-columns: 300px max-content min-content;
  column-gap: 48px;
  row-gap: 12px;
  align-items: center;
}

.economic-results__records-total {
  display: grid;
  grid-template-columns: 300px max-content;
  column-gap: 48px;
  align-items: center;
}

.economic-results__item-label {
  color: var(--secondary-text-color);
}

.economic-results__item-header {
  color: var(--primary-text-color);
}

.economic-results__item-value {
  margin: 0 0 2px 0;
  color: var(--primary-text-color);
}

.economic-results__item-unit {
  color: var(--primary-text-color);
  margin-left: -36px;
}

.main-button.primary.results__btn.economic-results__btn {
  margin-top: 0;
}

.economic-results__status {
  margin: 0 20px 0 8px;
}

.economic-results__record-divider {
  margin: 20px 0;
  height: 1px;
  width: 100%;
  background-color: var(--border-color);
}

.economic-results__detailed-value {
  text-align: right;
}


.tasks__table {
  max-height: calc(100vh - 64px - 180px);
  overflow: auto;
  padding-right: 8px;
  padding-bottom: 20px;
  margin-top: 38px;
  display: grid;
  width: 100%;
  grid-template-columns: minmax(250px, 45fr) minmax(180px, 30fr) minmax(max-content, 25fr) minmax(max-content, 110px) 82px;
}

.tasks__table-header {
  color: var(--secondary-text-color);
  margin-bottom: 12px;
  padding-right: 20px;
}

.tasks__empty-message {
  color: var(--primary-text-color);
}

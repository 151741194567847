.map {
  &.manual_mode {
    & .height-profile-panel__resizable-container {
      width: calc(100vw - 320px - 52px - 20px - 20px);
    }
  }
  &.optimization_calc {
    & .height-profile-panel__resizable-container {
      width: calc(100vw - 420px - 52px - 20px - 20px);
    }
  }
  &.auto_cs_calc,
  &.hydraulic_calc,
  &.economic_calc {
    & .height-profile-panel__resizable-container {
      width: calc(100vw - 860px - 52px - 20px - 20px);
    }
  }
}

.height-profile-panel__resizable-container {
  position: absolute;
  bottom: 35px;
  right: 20px;
  //left: -280px;
  //width: calc(100vw - 340px - 52px - 20px);
  max-width: calc(100vw /*- 340px*/ - 52px - 20px - 20px);
  min-width: 531px;
  z-index: 120;
}

.height-profile-panel {
  //height: 600px;
  padding: 20px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  background-color: white;
  overflow: hidden;
}

.height-profile-panel__top-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  margin-bottom: 24px;
}

.height-profile-panel__title-wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}

.height-profile-panel__title {
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.height-profile-panel__control-row-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  gap: 24px;
  flex-wrap: wrap;
}

.height-profile-panel__close-btn {
  align-self: start;
  height: 36px;
}

.height-profile-panel__select-row {
  display: flex;
  gap: 20px;
}

.height-profile-panel__chart-container {
  height: 249px;
  padding: 12px 0 16px 0;
}

.height-profile-panel__control-row {
  display: flex;
  align-items: center;
  gap: 20px;
}

.height-profile-panel__control-btns {
  display: flex;
}

.height-profile-panel__range {
  display: flex;
  gap: 20px;
}

.height-profile-panel__control-row-input {
  width: 116px;
}

.height-profile-panel__inputs {
  display: flex;
  align-items: center;
}

.height-profile-panel__minus-icn {
  & path {
    stroke: var(--secondary-text-color);
  }
}

.height-profile-panel__control-btn {
  width: 36px;
  height: 36px;
  font-size: 0;

  &.left {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.right {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.height-profile-panel__pan-btns {
  display: flex;
}

.main-button.primary .main-button__icon-wrapper .height-profile-panel__back-icn {
  & path {
    fill: none;
  }
  &.right {
    transform: rotate(180deg);
  }
}

.main-button.primary:disabled .main-button__icon-wrapper .height-profile-panel__back-icn,
.main-button.primary:disabled .main-button__icon-wrapper .height-profile-panel__zoom-icn {
  & path {
    fill: none;
    stroke: var(--secondary-text-color);
  }
}

.height-profile-panel__zoom-btns {
  display: flex;
  margin-right: 20px;
}

.height-profile-panel__loading-container {
  width: 100%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.laying-type-popup__container {
  position: absolute;
  width: 235px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  background: var(--white-color);
  padding: 20px;
}

.laying-type-popup__dropdown-container {
  margin-top: 20px;
}

.height-profile-panel__info-text {
  margin: 24px 0 20px 0;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 170%;
}

.height-profile-panel__calc-container {
  display: flex;
  width: 295px;
  gap: 20px;
  align-items: end;
}

.height-profile-panel__highlight-btn {
  cursor: pointer;

  &.non-active svg path {
    stroke: #8896A4;
  }
}

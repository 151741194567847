.login-page__container {
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: var(--primary-color);
}

.login-page__form {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 320px;
  padding: 24px 20px 24px 20px;
  background: #fff;
  border-radius: 4px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.login-page__form-label {
  margin-bottom: 2px;
  font-style: normal;
  font-weight: 800;
  font-size: 10px;
  line-height: 13px;
  color: var(--secondary-text-color);
}

.login-page__error-message {
  height: 13px;
  margin-top: 2px;
  font-weight: 800;
  font-size: 10px;
  line-height: 13px;
  color: var(--alert-color);
}

.login-page__account-btn{
  & > .main-button__icon-wrapper {
    justify-content: space-between;
  }
}

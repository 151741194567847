.base-header {
  /*position: fixed;
  width: 100%;
  z-index: 10;*/
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  max-height: 64px;
  min-height: 64px;
  background-color: var(--primary-color);
  padding: 0 20px 0 20px;
  align-items: center;
}

@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 200;
  src: url('/fonts/Mulish-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 300;
  src: url('/fonts/Mulish-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/Mulish-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 500;
  src: url('/fonts/Mulish-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  src: url('/fonts/Mulish-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/Mulish-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 800;
  src: url('/fonts/Mulish-ExtraBold.ttf') format('truetype');
}
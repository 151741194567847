.status__container {
  padding: 2px 4px;
  border-radius: 4px;
  color: var(--white-color);
  text-align: center;
  text-wrap: nowrap;

  &.new {
    background-color: var(--secondary-text-color);
  }

  &.done {
    background-color: var(--green-color);
  }

  &.progress {
    background-color: var(--blue-color);
  }

  &.non-actual {
    background-color: var(--orange-color);
  }

  &.error {
    background-color: var(--alert-color);
  }

  &.partly-calc {
    background-color: var(--background-color-3);
  }

  &.canceled {
    background-color: var(--purple-color);
  }
}

.search-input{
  display: flex;
  align-items: center;
  padding: 0 11px;
  width: 240px;
  height: 36px;
  background: var(--grey-color);
  border-radius: 4px;
  &:hover {
    background-color: var(--grey-hover-color);
  }
  &.focused {
    background-color: var(--grey-active-color);
  }
}
.input-placer{
  flex-grow: 1;
}
.offsets-table__container{

  .grid-table__header:nth-child(1){
    display: flex;
    align-items: center;
    grid-row-start: 1;
    grid-row-end: 3;
  }
  .grid-table__header:nth-child(2) {
    grid-column-start: 2;
    grid-column-end: 8;
  }

  .grid-table__header:nth-child(3) {
    grid-column-start: 8;
    grid-column-end: 10;
  }
}
.offsets-table__header{
  background: var(--grey-color) !important;
  color: var(--primary-text-color) !important;
  font-weight: 800;
  font-size: 12px;
  line-height: 15px;
  position: sticky;
  left: 0;
  z-index: 45;
}

.choices-table__container {
  & .grid-table__cell.text.sticky {
    left: 51px;
  }

  & :nth-child(2) {
    left: 51px;
  }
}

.choices-table__container.gpu {
  & .grid-table {
    grid-template-columns: 50px minmax(max-content, 1fr) minmax(168px, auto) minmax(112px, auto) minmax(132px, auto) minmax(132px, auto) minmax(112px, auto) minmax(120px, auto) minmax(112px, auto) minmax(170px, auto) minmax(170px, auto) minmax(132px, auto) minmax(120px, auto) minmax(140px, auto) minmax(140px, auto) minmax(140px, auto) minmax(140px, auto) minmax(140px, auto) minmax(140px, auto) minmax(140px, auto) minmax(140px, auto) minmax(140px, auto) minmax(140px, auto) minmax(140px, auto) minmax(140px, auto) minmax(140px, auto) minmax(140px, auto) minmax(132px, auto) minmax(132px, auto) minmax(170px, auto) minmax(120px, auto) minmax(170px, auto) minmax(120px, auto) !important;
  }
}
.choices-table__container.gpu.stripped_down {
  & .grid-table {
    grid-template-columns:
            50px minmax(max-content, 1fr)
            minmax(168px, auto)
            minmax(112px, auto)
            minmax(132px, auto)
            minmax(132px, auto)
            minmax(112px, auto)
            minmax(120px, auto)
            minmax(112px, auto)
            minmax(170px, auto)
            minmax(170px, auto)
            minmax(140px, auto)
            minmax(132px, auto)
            minmax(132px, auto)
            minmax(120px, auto) !important;
  }
}

.choices-table__container.acu {
  & .grid-table {
    grid-template-columns: 50px minmax(max-content, 1fr) minmax(100px, auto) minmax(112px, auto) minmax(140px, auto) minmax(140px, auto) minmax(120px, auto) minmax(132px, auto) minmax(120px, auto) minmax(120px, auto) minmax(112px, auto) minmax(120px, auto) minmax(100px, auto) minmax(100px, auto) minmax(156px, auto) minmax(156px, auto) minmax(180px, auto) minmax(100px, auto) minmax(170px, auto) minmax(170px, auto) minmax(156px, auto) !important;
  }
}

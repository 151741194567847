.table-container{
  overflow: auto;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  position: relative;
}
.grid-table {
  display: grid;
  width: fit-content;
  min-width: 100%;
  box-sizing: border-box;
  background: var(--border-color);
  gap: 1px;
  font-weight: 800;
  font-size: 12px;
  line-height: 15px;
  color: var(--primary-text-color);
}

.grid-table__header {
  background: var(--grey-color);
  padding: 11px 16px;
  position: sticky;
  top: 0;
  z-index: 45;

  &.sticky {
    left: 0;
    z-index: 48;

    &:after {
      display: block;
      content: '';
      position: absolute;
      width: 1px;
      height: 100%;
      top: 0;
      right: -1px;
      background-color: var(--border-color);
    }

    &:before {
      display: block;
      content: '';
      position: absolute;
      width: 1px;
      height: 100%;
      top: 0;
      left: -1px;
      background-color: var(--border-color);
    }
  }

  &:before {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: -1px;
    left: 0;
    background-color: var(--border-color);
  }
}



.input.table-input {
  padding: 10.5px 16px;

  &.no-padding {
    padding: 10.5px 0;
  }
}

.grid-table__cell {
  max-width: 1fr;
  background: var(--white-color);

  .table-error__icon{
    display: inline;
  }
  &.selection-cell {
    display: flex;
    justify-content: space-around;
  }

  &.boolean {
    display: flex;
    padding: 0 16px;
  }
  &.select {
    display: flex;
    justify-content: space-around;
    padding: 0 16px;

    & .radio__input {
      margin: 0;
    }
  }
  .dropdown__options-list{
    max-height: 98px;
  }

  &.optionasbutton {

    & .grid-table__cell-btn {
      width: 100%;

      &:not(.disabled) {
        & .dropdown__value {
          color: var(--primary-text-color);
        }
        & .dropdown__value-container {
          cursor: pointer;
        }
      }
    }
  }

  &.column,
  &.sticky {
    position: sticky;
    top: unset;
    left: 0;
    z-index: 40;

    &:before {
      display: block;
      content: '';
      position: absolute;
      width: 1px;
      height: 100%;
      top: 0;
      left: -1px;
      background-color: var(--border-color);
    }

    &:after {
      display: block;
      content: '';
      position: absolute;
      width: 1px;
      height: 100%;
      top: 0;
      right: -1px;
      background-color: var(--border-color);
    }
  }

  & .grid-table__header {
    z-index: 40;
  }
}

.grid-table__cell > .input-wrapper {
  width: 100%;
  height: 100%;

  .input.disabled {
    border: none;
    color: var(--secondary-text-color)
  }

  .input {
    height: 100%;
    border-radius: 0;
    background-color: var(--white-color);
    color: var(--primary-text-color)
  }
}

.expand-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    transform: rotate(-90deg);
  }

  &.open svg {
    transform: rotate(0deg);
  }
}

.grid-table__expand-row {
  background: var(--white-color);
  padding: 12px;
}
.grid-table__expand-row-cell{
  display: flex;
  flex-direction: column;
  background-color: var(--white-color);
}

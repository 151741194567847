.gpu-choices-control{
  display: flex;
  gap: 80px;
  .dropdown__container{
    width: 152px;
  }
}
.gpu-choices-list-view{
  display: flex;
  height: 100%;
  max-height: calc(100vh - 271px);
  border: 1px solid var(--border-color);
}
.gpu-choices-list-view__btns{
  max-width: 320px;
  width: 20%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: auto;
  border-right: 1px solid var(--border-color)
}
.gpu-choices-list-view__card {
  display: flex;
  flex-grow: 4;
}
.view-type {
  display: flex;
  gap: 20px;
  margin-top: 15px;
}
.view-type-btn{
  cursor: pointer;
  &:not(.active) svg path{
    stroke: var(--secondary-text-color);
  }
}
.gpu-card__container{
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
  overflow: auto;
}
.gpu-card__tables{
  display: flex;
  gap: 40px;
}
.gpu-card__tables .table-container {
  overflow: visible;
  height: min-content;
}
.gpu-card__tables > div:first-child{
  padding-right: 40px;
  border-right: 1px solid var(--border-color);
}
.gpu-card__tables .grid-table__header{
  position: relative;
}
.gpu-card__tables> :first-child{
    //margin-right: 40px;
  }

.gpu-choices-control__filters{
  display: inline-flex;
  gap: 20px;
}
.choices-table__container{
  .grid-table__cell.sticky.text{
    left: 51px;
  }
}
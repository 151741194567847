.elements-panel__btn {
  width: 100%;
  display: flex;
  position: relative;
  padding: 3px 4px;
  gap: 12px;
  align-items: center;
  border: none;
  border-bottom: 1px solid var(--border-color);
  background-color: var(--white-color);
  color: var(--primary-text-color);
  text-align: start;

  &:hover {
    background: var(--white-hover-color);
  }

  &:active {
    background: var(--white-active-color);
  }

  .select-marker {
    position: relative;
    width: 4px;
    height: 33px;
    border-radius: 4px;
  }

  &.selected > .select-marker {
    background: var(--primary-color);
  }
}

.elements-panel__btn-warning-icon {
  position: absolute;
  right: 20px;
}

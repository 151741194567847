.cs-calculation-indices-table__container {
  width: min-content;

  .grid-table {
    grid-template-columns: repeat(6, max-content) !important;

    & :nth-child(7) {
      &:before {
        display: none;
      }
    }
  }
}

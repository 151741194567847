.plant-switcher {
  min-height: 42px;
  max-height: 42px;
  display: grid;

  & > div {
    display: flex;
    cursor: pointer;
    color: #8DA5BD;
    justify-content: flex-start;
    align-items: center;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    padding-left: 24px;
    border-bottom: 2px solid #8DA5BD;

    &.selected {
      color: #0074BC;
      border-bottom-color: #0074BC;
    }
  }
}

.choices_dropdown {
  .dropdown__options-list {
    display: none;
  }
}

.checkbox_field {
  display: inline-flex;
  align-items: center;
  gap: 7px;
  color: var(--secondary-text-color);
}

.checkbox_field > div {
  padding-top: 15px;
}
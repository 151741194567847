.indices-pipes-calculation-table__container {

  :nth-child(n+2):nth-child(-n+18) {
    grid-column-start: span 5;
  }

  :nth-child(19) {
    left: 0;
    z-index: 48;
  }

  :nth-child(5n+17):nth-child(-n+190) {
    pointer-events: none;

    .input {
      color: var(--secondary-text-color)
    }
  }

  :nth-of-type(n+193):nth-child(-n+210) {
    grid-column-start: span 5;
  }

  :nth-child(n+193):nth-child(-n+193) {
    pointer-events: none;

    .input {
      color: var(--secondary-text-color);
    }
  }

  :nth-child(n+212):nth-child(-n+212) {
    pointer-events: none;

    .input {
      color: var(--secondary-text-color);
    }
  }

  :nth-of-type(n+212):nth-child(-n+228) {
    grid-column-start: span 5;
  }

  & .grid-table__header:first-child {
    &:last-child {
      &:before {
        display: none;
      }
    }
  }
}

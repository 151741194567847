.pipe-table{
  width: 878px;
  height: 471px;
}

.pipe-params__filters{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 16px;
}
.filter-title{
  font-weight: 800;
  font-size: 10px;
  line-height: 13px;
  color: #8896A4;
}
.empty-table{
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: var(--secondary-text-color);
}

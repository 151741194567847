.dropzone__container {
  flex: 1 1 0;
  width: 0;


  & input {
    display: block;
  }
}

.dropzone {
  box-sizing: border-box;
  height: calc(100% - 17px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 28px 6px;
  text-align: center;
  background-color: var(--grey-color);
  border: 2px dashed var(--border-color);
  border-radius: 4px;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: var(--grey-hover-color);
  }

  &.active {
    background: var(--grey-active-color);
  }

  &.error {
    border: 1px dashed #ff3131;

    & .dropzone__text,
    .dropzone__text_bold {
      color: #ff3131;
    }
  }

  &.disabled {
    background: none;

    & .dropzone__text_bold {
      color: var(--secondary-text-color);
      text-decoration: none;
      font-weight: 700;
    }
  }
}

.dropzone__text-wrapper {
  min-height: 20px;
  display: inline-block;
}

.dropzone__text {
  color: var(--secondary-text-color);
  font-weight: 700;
  line-height: 20px;

  &_bold {
    color: var(--primary-color);
    font-weight: 700;
    line-height: 20px;
    text-decoration: underline;
  }
}

.layers-table__container {
  width: 880px;

  & .grid-table {
    grid-template-columns: minmax(max-content, auto) 172px  299px !important;
  }
}

.layers-table__container.edit {
  & .grid-table {
    grid-template-columns: 48px minmax(max-content, auto) 172px 299px !important;
  }
}

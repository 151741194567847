.info-block {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  padding: 8px;
  z-index: -10;
  border-radius: 4px;
  min-width: 180px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.09);
  background-color: var(--white-color);
  color: var(--secondary-text-color);
  div {
    // padding: 10px 0;
    border-bottom: 1px solid var(--alert-color);
  }
  div:last-child {
    border-bottom: none;
  }

  &.visible {
    height: fit-content;
    visibility: visible;
    z-index: 10;
  }
  &.opacity {
    opacity: 1;
  }
  &.error {
    background: var(--alert-background);
    color: var(--alert-color);
  }
  &.warning {
    background: var(--warning-background);
    color: var(--warning-color);
  }
}

.cs-settings__container {
  width: 776px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  min-height: 200px;
}

.cs-settings__subtitle {
  font-weight: 800;
  font-size: 14px;
  line-height: 18px;
  color: var(--primary-text-color);
}

.cs-settings__grid-row {
  display: grid;
  grid-template-columns: repeat(4, 182px);
  grid-column-gap: 16px;
}

.cs-settings__checkbox-container {
  display: flex;
  gap: 8px;
  color: var(--secondary-text-color);
  align-items: center;
  white-space: nowrap;
}

.cs-settings__divider {
  width: 100%;
  height: 1px;
  background-color: var(--border-color);
}

.edit-element-panel {
  position: absolute;
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;
  background-color: var(--white-color);
  display: flex;
  min-width: 420px;
  max-width: 816px;
  height: calc(100vh - 64px);
  left: 372px;
  padding: 28px 20px;
  z-index: 10;
  overflow-y: auto;
  overflow-x: hidden;

  &.full-screen {
    max-width: unset;
  }
}

.edit-element-panel__header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  color: var(--primary-text-color);
}

.edit-element-panel__header-right {
  display: flex;
  gap: 24px;
}

.edit-element-panel__maxmin-btn {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 28px;
  height: 28px;
  margin-right: 16px;
  border-radius: 50%;
  background-color: var(--secondary-color);
  cursor: pointer;

  &.full-screen {
    background-color: var(--primary-color);
  }

  &.single-btn {
    margin-right: 0;
  }
}

.edit-element-panel__table {
  display: grid;
  grid-template-columns: 182px 182px;
  grid-column-gap: 16px;
  grid-row-gap: 20px;

  & > .edit-element-panel__col-label {
    font-weight: 800;
    font-size: 10px;
    line-height: 13px;
    color: var(--secondary-text-color);
    padding: 20px 0 2px 0;
  }

  .common-row {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}

.edit-element-panel__subtitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 800;
  font-size: 14px;
  line-height: 18px;
  color: var(--primary-text-color);
}

.edit-element-panel__table-v2 {
  grid-template-columns: 77px 1fr;
}

.edit-element-panel__table-v3 {
  grid-template-columns: 150px 1fr;
  grid-auto-rows: 37px;
}

.edit-element-panel__table-v2, .edit-element-panel__table-v3 {
  display: grid;
  width: 100%;
  background-color: var(--border-color);
  grid-column-gap: 1px;
  grid-row-gap: 1px;
  border-radius: 4px;
  justify-items: stretch;
  align-items: stretch;
  border: 1px solid var(--border-color);

  & > *:not(.edit-element-panel__cell) {
    height: 37px;
    padding: 11px 0 0 16px;
  }

  & > .edit-element-panel__col-label {
    font-weight: 800;
  }

  & > .edit-element-panel__row-label, .edit-element-panel__col-label {
    background-color: var(--grey-color);
    color: var(--primary-text-color);
    font-size: 12px;
    line-height: 15px;
  }

  & > .edit-element-panel__row-label, .edit-element-panel__cell {
    font-weight: 600;
  }

  .edit-element-panel__cell {
    background-color: var(--white-color);
  }

  .edit-element-panel__cell:last-child {
    border-radius: 0 0 4px 0;
  }

  .edit-element-panel__cell > .input-wrapper {

    .input {
      background-color: var(--white-color);
      border: none;
      border-radius: 0;
    }
  }
}

.marker-index {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  border-radius: 20px;
  background-color: var(--grey-color);
  color: var(--primary-text-color)
}

.edit-element-panel__complex-col {
  display: flex;
  justify-content: space-between;
}

.edit-element-panel__mole-sum {
  display: flex;
  align-items: center;
  padding-right: 16px;
  height: 15px;
  color: var(--secondary-text-color);

  & svg {
    margin-left: 4px;
  }

  &.error {
    color: var(--alert-color);
  }
}

.edit-element-panel__mole-sum-symbol {
  height: 17px;
}

.edit-element-panel__height-row {
  align-self: end;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.edit-element-panel__redo-btn-container {
  height: 36px;
  width: 36px;
}

.edit-element-panel__redo-btn {
  height: 100%;
  width: 100%;
}

.edit-element-panel__lock-row {
  margin-bottom: -8px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.edit-element-panel__lock-btn {
  font-size: 0;
  border-radius: 50%;
  background-color: var(--grey-color);
  cursor: pointer;

  &:hover {
    background-color: var(--grey-hover-color);
  }

  &:after {
    background-color: var(--grey-active-color);
  }

  &.locked {
    background-color: var(--primary-color);

    &:hover {
      background-color: var(--primary-hover-color);
    }

    &:after {
      background-color: var(--primary-active-color);
    }
  }
}
.edit-element-panel__copy-btn {
   font-size: 0;
  border-radius: 4px;
  background-color: var(--white-color);
  cursor: pointer;
  svg path {
    stroke: var(--secondary-text-color);
  }

  &:hover {
    background-color: var(--white-hover-color);
  }

  &:after {
    background-color: var(--white-active-color);
  }
}
.edit-element-panel__title-container{
  display: flex;
  gap: 12px;
}

.basic-costs-table__container{
  width: min-content;

  & .grid-table {
    grid-template-columns: repeat(2, max-content) !important;

    & .grid-table__cell:nth-child(15),
    & .grid-table__cell:nth-child(18),
    & .grid-table__cell:nth-child(21),
    & .grid-table__cell:nth-child(24),
    & .grid-table__cell:nth-child(27),
    & .grid-table__cell:nth-child(30),
    & .grid-table__cell:nth-child(33),
    & .grid-table__cell:nth-child(36){
      position: relative;

      & .grid-table__header {
        padding: 0;
      }
    }

    & .grid-table__header:first-child {
      &:last-child {
        &:before {
          display: none;
        }
      }
    }

    & :nth-child(15),
    & :nth-child(21),
    & :nth-child(27),
    & :nth-child(33) {
      z-index: 42;
    }
  }

  & .complex-column-header {
    display: grid;
    grid-template-columns: 154px 250px;

    & .complex-column-header-cell {
      background-color: var(--grey-color);
      padding: 11px 16px;
    }

    &.first .complex-column-header-cell:nth-child(1) {
      position: absolute;
      height: 73px;
      width: 154px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      z-index: 10;
    }

    & .complex-column-header-cell:nth-child(2) {
      grid-column-start: 2;
      grid-column-end: 3;
      border-left: 1px solid var(--border-color);
    }
  }
}



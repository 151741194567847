.ruler-popup {
  display: flex;
  width: fit-content;

  & .mapboxgl-popup-tip {
    display: none;
  }

  & .mapboxgl-popup-content {
    padding: 3px 5px 5px 5px;
    background: #FF33C6;
    border-radius: 4px;
    color: #FFF;
    font-family: Mulish, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

.optimization-settings__edit-element-panel {
  position: relative;
  left: unset;
  max-height: calc(100vh - 64px - 76px);

  & .close-btn{
    display: none;
  }

  & .edit-element-panel__header {
    margin-bottom: 8px;
  }
}

.optimization-settings__complex-subtitle {
  justify-content: start;
  gap: 20px;
}

.optimization-settings__calc-btn-wrapper {
  position: absolute;
  width: calc(100% - 40px);
  bottom: 20px;
  display: flex;
  justify-content: space-between;
  gap: 12px;
}

.optimization-settings__calc-btn {
  flex: 1 1;
}

.intermediate-element__container {
  width: 258px;
  position: absolute;
  bottom: 48px;
  right: 24px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background: var(--white-color);
}

.intermediate-element__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--grey-color);
  padding: 12px 20px;
  color: var(--primary-text-color);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.intermediate-element__subtitle {
  color: var(--primary-text-color);
  margin-bottom: 20px;
}

.intermediate-element__content {
  padding: 20px;
}

.intermediate-element__close-icn {
  & path {
    stroke: #8896A4;
  }
}

.intermediate-element__params-panel {
  padding-left: 36px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  &.radio-params {
    padding-left: 0;
  }
}

.intermediate-element__divider {
  margin: 20px 0;
}

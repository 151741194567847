.radio__input-row {
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:not(.disabled) {
    & .radio__label {
      cursor: pointer;
    }
  }

  &.with-children {
    display: flex;
    gap: 12px;
    width: 100%;
    white-space: initial;
  }
}

.radio__input {
  all: unset;
  position: relative;
  width: 16px;
  height: 16px;
  margin: 0 8px 0 0;
  background-color: var(--white-color);
  border: 1px solid var(--primary-color);
  border-radius: 50%;
  -webkit-appearance: none;
  appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;

  &:disabled {
    border: 1px solid var(--secondary-text-color);

    &::before {
      box-shadow: inset 6px 6px var(--secondary-text-color);
    }
  }

  &:not(:disabled) {
    cursor: pointer;
  }
}

/*.radio__input::before {
  width: 6px;
  height: 6px;
  background-color: var(--primary-color);
  //box-shadow: inset 6px 6px var(--primary-color);
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  content: '';
}*/

.radio__input:checked/*::before*/{
  //transform: scale(1);
  background-image: url("./selectedIcon.svg");
  background-repeat: no-repeat;
}

.radio__label {
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  max-width: 70%;
  color: var(--primary-text-color);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.radio__children-content {
  flex: 1 1;
}


.layer-control {
  position: absolute;
  top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 2px;
  right: calc(60px + 116px + 20px);
}

.layer-control__btn {
  height: 36px;
  width: 100px;

  & .layer-control__btn-arrow-icn {
    margin-left: 4px;
  }

  & .layer-control__btn-arrow-icn path {
    stroke: var(--white-color);
    fill: var(--white-color);
  }

  &.open .layer-control__btn-arrow-icn {
    transform: rotate(-180deg);
  }

  &.main-button.primary .main-button__icon-wrapper .layer-control__btn-warning-icn path {
    fill: #FC7818;
  }
}

.layer-control__btn-warning-icn {
  width: 12px;
  height: 12px;
}

.layer-control__warning-wrapper {
  display: flex;
  gap: 4px;
}

.layer-control__dn-layers-warning-btn {
  position: relative;
  cursor: pointer;
}

.layer-control__dn-layers-warning-text {
  position: absolute;
  top: 24px;
  left: -32px;
  padding: 8px;
  background: var(--warning-background);
  color: var(--warning-color);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.09);
  border-radius: 4px;
  z-index: 10;
  width: 146px;
  text-align: left;

  &.outdated-text {
    top: 18px;
    left: -52px;
    width: 118px;
  }
}

.layer-control__container {
  display: flex;
  background: var(--primary-color);
  border-radius: 4px;
  color: var(--white-color);

  &:not(.open) {
    display: none;
  }
}

.layer-control__column {
  position: relative;
  padding: 0 12px 4px 12px;

  &.second {
    padding: 0 12px 4px 13px;
    &:before {
      position: absolute;
      left: 0;
      height: 100%;
      width: 1px;
      background-color: var(--background-color-2);
      content: '';
    }
  }
}

.layer-control__layer-btn {
  position: relative;
  display: flex;
  justify-content: flex-start;
  gap: 4px;
  align-items: center;
  height: 33px;
  width: 146px;
}

.layer-control__layer-btn-checkbox .checkbox-content {
  line-height: normal;
  font-weight: 700;
  font-size: 12px;
  color: var(--white-color);
}

.layer-control__layer-btn-radio {
  & .radio__label {
    line-height: normal;
    font-weight: 700;
    font-size: 12px;
    color: var(--white-color);
    max-width: 100%;
  }

  &.disabled .radio__label {
    color: var(--dark-bg-secondary-text);
  }

  & .radio__input:disabled {
    border-color: var(--dark-bg-secondary-text);
    background-color: #CED1EC;
  }
}

.layer-control__layer-btn:first-child {
  & .layer-control__layer-btn-radio {
    width: 100%;
  }
}

.layer-control__column-title {
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.layer-control__list-container{
  display: flex;
  flex-direction: column;

  &:not(.reverse) .layer-control__layer-btn {
    &:not(:last-child) {
      &:after {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: var(--background-color-2);
        content: '';
      }
    }
  }

  &.reverse {
    flex-direction: column-reverse;

    & .layer-control__layer-btn {
      &:not(:first-child) {
        &:after {
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 1px;
          background-color: var(--background-color-2);
          content: '';
        }
      }
    }
  }
}

.layer-control__calc-btn {
  padding: 4px 12px;
  line-height: 16px;
}

.dropdown__container {
  position: relative;
  width: 100%;

  &.pressed-dropdown {
    width: fit-content;

    & .dropdown__value-container {
      background: none;
      height: 20px;
      padding: 0 4px;

      &:not(:disabled):hover {
        background: var(--white-hover-color);
      }

      &:not(:disabled):active {
        background: var(--white-active-color);
      }
    }

    & .dropdown__options-list {
      width: fit-content;
      min-width: 212px;
    }

    & .dropdown__value {
      font-size: 14px;
      font-weight: 800;
    }
  }
}

.dropdown__value-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  width: 100%;
  height: 36px;
  padding: 0 8px 0 12px;
  background: var(--grey-color);
  border: none;
  border-radius: 4px;

  &:not(:disabled):hover {
    background: var(--grey-hover-color);
  }

  &:not(:disabled):active {
    background: var(--grey-active-color);
  }

  &:disabled {
    background-color: var(--white-color);
    border: 1px solid var(--border-color);
    border-radius: 4px;

    & .dropdown__value {
      color: var(--secondary-text-color);
    }

    & .dropdown__indicator {
      svg path {
        fill: var(--secondary-text-color);
        stroke: var(--secondary-text-color);
      }
    }
  }
}

.grid-table__cell {
  & .dropdown__value-container {
    background: var(--white-color);

    &:not(:disabled):hover {
      background: var(--white-color);
    }

    &:not(:disabled):active {
      background: var(--white-color);
    }
  }
}

.dropdown__value-wrapper {
  position: relative;
  max-width: calc(100% - 20px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
}

.dropdown__value {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: var(--primary-text-color);

  &.animate:hover {
    -webkit-animation: moveLeft 15s linear 1.5s 1;
  }
}

.dropdown__indicator {
  display: flex;

  &.open {
    transform: rotate(-180deg);
  }
}

.dropdown__options-list {
  position: absolute;
  width: 100%;
  z-index: 100;
  background-color: white;
  margin-top: 5px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  max-height: 270px;
  overflow: auto;

  /*&::-webkit-scrollbar {
    background: transparent;
    width: 14px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(237, 237, 237, 1);
    background-clip: content-box;
    border: 4px solid transparent;
    border-radius: 14px;
    min-height: 30px;
  }

  &::-webkit-scrollbar-track-piece
  {
    background-color: transparent;
    -webkit-border-radius: 6px;
  }*/

  &.open-up {
    bottom: calc(32px + 5px);
    margin-top: 0;
  }

  &.no-display {
    opacity: 0;
  }
}

.dropdown__option {
  display: flex;
  align-items: center;
  height: 32px;
  padding: 0 12px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:not(:last-child) {
    border-bottom: 1px solid var(--border-color);
  }

  &:not(.disabled):hover {
    background: var(--white-hover-color);
  }

  &:not(.disabled):active {
    background-color: var(--white-active-color);
  }
}

.dropdown__option-value {
  //width: 100%;
  position: relative;
  color: var(--primary-text-color);

  &.animate:hover {
    -webkit-animation: moveLeft 15s linear 1.5s 1; /* Safari 4.0 - 8.0 */
    //animation: moveLeft 5s linear 3s 1 alternate;
  }

  &.disabled {
    color: var(--secondary-text-color);
  }
}

.dropdown__empty-options {
  margin: 8px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #838383;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@keyframes moveLeft {
  from {
    margin-left: 0;
  }
  80% {
    margin-left: -300%;
  }
  to {
    margin-left: 0;
  }
}

.dots-btn__options-list {
  width: 167px;
}

.dropdown__container__label {
  display: flex;
  gap: 3.5px;
  padding-bottom: 2px;

  div:first-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

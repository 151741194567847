.station-result-table-2 {
  .grid-table__header:nth-child(2) {
    grid-column-start: 2;
    grid-column-end: 9;
  }

  .grid-table__header:nth-child(3) {
    grid-column-start: 9;
    grid-column-end: 16;
  }
}

.input {
  width: 100%;
  padding: 10.5px 10.5px 10.5px 8px;
  color: var(--primary-text-color);
  background: var(--grey-color);
  border-radius: 4px;
  outline: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  & input::placeholder {
    color: var(--secondary-text-color)
  }

  &:not(.disabled) {
    cursor: text;

    &:hover {
      background-color: var(--grey-hover-color);
    }

    &:active, &.focused {
      background-color: var(--grey-active-color);
    }
  }

  &.disabled {
    background-color: var(--white-color);
    border: 1px solid var(--border-color);
    border-radius: 4px;
    color: var(--secondary-text-color);
    padding: 9.5px 9.5px 9.5px 8px;
  }

  &.with-unit {
    padding-right: 24px;
  }
}

.input-wrapper {
  position: relative;

  & input {
    all: unset;
    min-width: 60px;
    flex: 1;
  }
}

.input-wrapper.error {
  .input-wrapper__label div {
    color: var(--alert-color)
  }

  .main-button:not(:last-child) svg path {
    stroke: var(--alert-color)
  }

  .input {
    padding: 9.5px 9.5px 9.5px 7px;
    border: 1px solid var(--alert-color)
  }
}

.input-wrapper.warning:not(.error) {
  .input-wrapper__label div {
    color: var(--warning-color)
  }

  .main-button:not(:last-child) svg path {
    stroke: var(--warning-color)
  }

  .input {
    padding: 9.5px 9.5px 9.5px 7px;
    border: 1px solid var(--warning-color)
  }
}

.unit {
  //position: absolute;
  //bottom: 10.5px;
  padding-left: 2px;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  //right: 10.5px;
  color: var(--secondary-text-color);
}

.unit-wrapper {
  display: flex;
  justify-content: space-between;

  .table-error__icon {
    display: none;
  }
}

.input-wrapper__label {
  display: flex;
  align-items: center;
  gap: 3.5px;
  padding-bottom: 2px;
  color: var(--secondary-text-color);

  div:first-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.input__loader {
  width: 15px;
  height: 15px;
  background-image: url(../../images/gpp_loader_no_background.gif);
  background-size: contain;
  background-repeat: no-repeat;
}



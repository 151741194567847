.scheme-container {
  display: flex;
  position: absolute;
  height: calc(100% - 52px);
  width: 100%;
  flex-grow: 1;
  &.grabbing {
    cursor: grabbing;
  }
  &.default {
    cursor: default;
  }
}
.scheme-canvas {
}
.scheme-canvas__to-pdf {
  display: none;
}

.not-found-view__container {
  position: relative;
  width: 100%;
  height: 100vh;
}

.not-found-view__form {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 400px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.not-found-view__content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.not-found-view__title,
.not-found-view__message {
  color: var(--primary-text-color);
  white-space: pre-wrap;
}

.main-page-header__container {
  position: relative;
  height: 64px;
  background-color: var(--primary-color);
  min-width: 1440px;
}

.project-list__header-logo {
  position: absolute;
  display: flex;
  gap: 8px;
  width: 220px;
  height: 64px;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
  text-transform: uppercase;
}

.project-list__header-label {
  padding-top: 5px;
  color: var(--white-color)
}

.project-list__header-btns {
  display: flex;
  width: 100%;

  .switcher_btn {
    height: 64px;
    width: 132px;
    border-radius: 0;
    border-bottom: 2px solid var(--white-color);
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;

    &:not(.active) {
      color: var(--dark-secondary-color);
      border-bottom: 2px solid var(--dark-secondary-color);
    }
  }
}

.project-list__header-user {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  width: 220px;
  height: 64px;
  align-items: center;
  justify-content: flex-end;
  gap: 13px;
  margin-right: 20px;
}

.username-label {
  font-weight: 700;
  font-size: 12px;
  line-height: 15.06px;
  color: var(--white-color);
  margin-left: 9px;
}

.project-list__user-container {
  position: relative;
  width: 100%;
  display: grid;
}

.project-list__user-btn {
  position: relative;
  background: none;
  border: none;
  border-radius: 4px;
  outline: none;
  display: flex;
  justify-content: space-between;
  padding: 4px;
  cursor: pointer;
  justify-self: end;

  &:hover {
    background: var(--background-color-6);
  }
}

.project-list__logout-btn {
  position: absolute;
  right: 0;
  top: 26px;
  padding: 8px 12px;
  margin-top: 2px;
  background: var(--white-color);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: var(--primary-text-color);
  cursor: pointer;

  &:hover {
    background: var(--white-hover-color);
  }
}

@media screen and (max-width: 1440px)  {
  .main-page-content__container.header-content__container {
    width: 520px;
    margin-left: 280px;
  }
  .main-page-header__container.header__container {
    min-width: 1080px;
  }
}

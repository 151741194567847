.style-guide__wrapper {
  padding: 80px;
  display: flex;
  flex-flow: row wrap;
  gap: 80px;
}

.style-guide__panel {
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.08);
  border-radius: 2px;
  padding: 24px 64px 40px 40px;
}

.style-guide__panel-title {
  margin-bottom: 32px;
  font-size: 36px;
}

.style-guide__panel-subtitle {
  margin: 24px 0 32px 0;
  font-size: 28px;
}

.style-guide__columns {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.style-guide__rows {
  display: flex;
  flex-direction: row;
  gap: 30px;
  flex-wrap: wrap;

  &:not(:last-child) {
    margin-bottom: 30px;
  }
}

.style-guide__icon-container {
  display: flex;
  align-items: center;
  gap: 12px;
}

.style-guide__circle-container {
  min-width: 180px;

  &.interactive {
    cursor: pointer;
  }
}

.style-guide__circle {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  box-shadow: 0 30px 50px rgba(131, 151, 166, 0.25);
}

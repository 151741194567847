.trunk-pipeline__panel.compressor-stations__panel {
  & .grid-table {
    width: fit-content;
    min-width: unset;
  }

}

.compressor-stations__table {

  & .grid-table__cell.combined {
    grid-row-start: span 2;
    display: flex;
    align-items: center;
  }

  & .dropdown__value-container:disabled {
    border: none;

    & .dropdown__indicator {
      svg path {
        fill: var(--primary-text-color);
        stroke: var(--primary-text-color);
      }
    }
  }
}

.mode-control {
  position: absolute;
  display: inline-flex;
  top: 20px;
  left: 20px;
  gap: 30px;

  & > button:first-child svg {
    margin: 0 2px;
  }

  #edit_icn path {
    fill: transparent;
    stroke: white;
  }

  & > button {
    border-radius: 4px;
  }
}

#mode-control__btn {
  &.selected {
    background-color: var(--background-color-5);
  }

  &.selected:hover {
    background-color: var(--background-color-5);
  }

  &.selected:active {
    background-color: var(--background-color-5);
  }
}

.mode-control__btns-container {
  display: inline-flex;
  gap: 12px;

  button {
    height: 36px;
    width: 36px;
  }

}

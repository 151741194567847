.auto-cs-calc__container {
  background-color: var(--white-color);
  display: grid;
  z-index: 10;
}

.auto-cs-calc__settings-content {
  position: relative;
  width: 860px;
}

.auto-cs-calc__results-content {
  width: 420px;
}

.auto-cs-calc__tabs {
  display: flex;
  margin-bottom: 20px;
}

.auto-cs-calc__tab {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  color: var(--text-color-3);
  padding: 12px 24px;
  white-space: nowrap;
  border-bottom: 2px solid var(--text-color-3);

  &:last-child {
    flex: 1;
  }

  &.error {
    color: var(--alert-color);
  }

  &.active {
    color: var(--primary-color);
    position: relative;
    border-bottom-color: var(--primary-color);

    &.error {
      color: var(--alert-color);
      border-bottom-color: var(--alert-color);
    }
  }

  & svg {
    height: 18px;
  }
}


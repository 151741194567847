.pipe-frame__list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  & > hr:last-child {
    display: none;
  }
}

.pipe-frame {
  display: grid;
  grid-template-columns: 20px minmax(auto, 152px) minmax(auto, 152px) 20px;
  column-gap: 10px;
  row-gap: 12px;

  & > :first-child {
    grid-row-start: span 5;
  }

  & > :nth-child(3) {
    grid-row-start: span 5;
    height: 20px;
  }

  & > .common-row {
    grid-column-start: span 2;
  }

  & > .pipe-type-filter {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button {
      width: 97px;
      height: 36px
    }
  }
  .left-column{
    margin-right: 5px;
  }
  .right-column{
    margin-left: 5px;
  }
}
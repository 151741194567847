.elements-panel {
  display: flex;
  flex-direction: column;
  max-width: 320px;
  min-width: 320px;
  height: calc(100vh - 64px);
  border-right: 1px solid var(--border-color);
  overflow-y: auto;
}

.elements-panel__group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  min-height: 40px;
  color: var(--text-color-1);
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  background-color: var(--background-color-1);
  text-transform: uppercase;
  padding: 0 8px 0 12px;
  cursor: pointer;

  & > button {
    padding: 0;
    min-height: 36px;
    min-width: 36px;
  }

  &.collapsed:not(:last-child) {
    border-bottom: 1px solid var(--border-color);
  }
}

.elements-panel__title-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.elements-panel__arrow-icn {
  &.collapsed {
    transform: rotate(270deg);
  }
}

.elements-panel__group__title{
  display: flex;
  gap: 4px;
  &> div:first-child{
    align-self: center;
    min-width: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &> div:last-child{
    display: inline-flex;
    gap: 7.5px;
  }
  white-space: nowrap;
}
.elements-panel__text {
  color: var(--text-color-2);
  text-align: center;
  padding: 20px 0 20px 0;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
}

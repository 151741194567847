.cs-unit-costs-table__container{
  width: min-content;

  .grid-table {
    grid-template-columns: repeat(5, max-content) !important;

    & .grid-table__header:first-child {
      &:last-child {
        &:before {
          display: none;
        }
      }
    }
  }

  & .grid-table__cell:nth-last-child(2) {
    grid-column-start: span 4;
  }
}


.black-pipe-prices-table__container{
  .grid-table{
    grid-template-columns: repeat(87, minmax(max-content, 1fr)) !important;

    & .grid-table__header:first-child {
      grid-column-start: span 2;

      &:last-child {
        &:before {
          display: none;
        }
      }
    }

    & .grid-table__cell.column {
      left: 124px;
      z-index: 45;
    }
  }
  :nth-child(n+2):nth-child(-n+18) {
    grid-column-start: span 5;
  }
  /*:first-child{
    left: 130px;
    grid-column-start: span 2;
  }*/
  :nth-child(19){
    grid-column-start: span 2;
    left: 0;
    z-index: 48;
  }
  :nth-child(105){
    //display: flex;
    grid-row-start: span 4;
    z-index: 45;
    left: 0;

    &:before {
      display: none;
    }
  }
}

.blocking-window__error-container {
  display: flex;
  max-width: 400px;
  align-items: center;
  flex-direction: row;
  gap: 8px;
}

.error-window__errors-list-container {
  display: flex;
  flex-direction: column;
  max-width: 85%;
}

.blocking-window__error-icon {
  align-self: start;
}

.error-window__errors-list {
  max-height: 200px;
  overflow: auto;
  padding-inline-start: 24px;
  margin-block-start: 8px;
}

.blocking-window__error-message {
  max-width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}


/*=========================

  Text styles

 ==========================*/

.button-text {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;

}

.header1 {
  font-weight: 900;
  font-size: 20px;
  line-height: 25px;
}

.header2 {
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
}

.header3 {
  font-weight: 800;
  font-size: 14px;
  line-height: 18px;
}

.header4 {
  font-weight: 800;
  font-size: 12px;
  line-height: 15px;
}
.normal-text {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
}

.label {
  font-weight: 800;
  font-size: 10px;
  line-height: 13px;
}

.label-primary {
  font-weight: 600;
  font-size: 10px;
  line-height: 13px;
}

h1 {
  font-weight: 900;
  font-size: 20px;
  line-height: 25px;
}

h2 {
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
}

h3 {
  font-weight: 800;
  font-size: 14px;
  line-height: 18px;
}
h4 {
  font-weight: 800;
  font-size: 12px;
  line-height: 15px;
}

.field-label-text{
  font-weight: 800;
  font-size: 10px;
  line-height: 15.06px;
  color: var(--secondary-text-color)
}

.primary-text {
  color: var(--primary-text-color)
}

.link {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: var(--primary-color);
  text-underline-offset: 2px;
  cursor: pointer;

  &:hover {
    color: #1E92DA;
  }

  &:active {
    color: #005CB4;
  }
}

.no-margin {
  margin: 0;
}

/*=========================

  Color styles

 ==========================*/

#root {
  --primary-color: #0074BC;
  --primary-hover-color: #0E82CA;
  --primary-active-color: #147EC6;
  --primary-selected-color: #2892DA;
  --primary-light-color: #1E88D0;
  --primary-light-hover-color: #2690D8;
  --primary-light-active-color: #147EC6;
  --primary-light-selected-color: #2892DA;
  --primary-text-color: #373737;
  --text-color-1: #656565;
  --text-color-2: #949494;
  --text-color-3: #8DA5BD;
  --text-color-4: #9b9b9b;
  --text-color-5: #947D2D;
  --secondary-text-color: #8896A4;
  --border-color: #E4E9EF;
  --background-color-1: #F3F6FA;
  --background-color-2: #3D82D3;
  --background-color-3: #DBAA2A;
  --background-color-4: #F8EBCB;
  --background-color-5: #F18827;
  --background-color-6: #0C80C8;
  --grey-color: #EEF3F9;
  --grey-hover-color: #EAEFF5;
  --grey-active-color: #E6EBF1;
  --disabled-color: #F4F4F5;
  --white-color: #FFFFFF;
  --white-hover-color: #F9FBFC;
  --white-active-color: #F7F9FD;
  --dark-secondary-color: #D1DEED;
  --dark-bg-secondary-text: #89B3E4;
  --secondary-color: #E2EBF3;
  --secondary-hover-color: #D8E1E9;
  --secondary-active-color: #D3DCE4;
  --alert-color: #E83232;
  --alert-hover-color: #D51F1F;
  --alert-active-color: #CA1414;
  --alert-background: #FFEAEA;
  --btn-active-color: #F18827;
  --btn-active-color-hover: #F79146;
  --btn-active-color-pressed: #E47B30;
  --warning-color: #FC7818;
  --warning-background: #FFECD5;
  --green-color: #00BC84;
  --orange-color: #FF7F48;
  --blue-color: #0B8DDE;
  --purple-color: #BF40BF;
}

.color-primary {
  color: var(--primary-color);
}

.bg-primary {
  background-color: var(--primary-color);

  &:not(:disabled):hover {
    background-color: var(--primary-hover-color);
  }

  &:not(:disabled):active {
    background-color: var(--primary-active-color);
  }
}

.color-primary-selected {
  color: var(--primary-selected-color);
}

.color-primary-light {
  color: var(--primary-light-color);
}

.bg-primary-light {
  background-color: var(--primary-light-color);

  &:not(:disabled):hover {
    background-color: var(--primary-light-hover-color);
  }

  &:not(:disabled):active {
    background-color: var(--primary-light-active-color);
  }
}

.color-primary-light-selected {
  color: var(--primary-light-selected-color);
}

.color-primary-text {
  color: var(--primary-text-color);
}

.color-secondary-text {
  color: var(--secondary-text-color);
}

.color-text-1 {
  color: var(--text-color-1);
}

.color-text-2 {
  color: var(--text-color-2);
}

.color-text-3 {
  color: var(--text-color-3);
}

.color-text-4 {
  color: var(--text-color-4);
}

.color-text-5 {
  color: var(--text-color-5);
}

.color-border {
  color: var(--border-color);
}

.bg-border {
  background-color: var(--border-color);
}

.bg-col-1 {
  background-color: var(--background-color-1);
}

.bg-col-2 {
  background-color: var(--background-color-2);
}

.bg-col-3 {
  background-color: var(--background-color-3);
}

.bg-col-4 {
  background-color: var(--background-color-4);
}

.bg-col-5 {
  background-color: var(--background-color-5);
}

.bg-col-6 {
  background-color: var(--background-color-6);
}

.bg-grey {
  background-color: var(--grey-color);

  &:not(:disabled):hover {
    background-color: var(--grey-hover-color);
  }

  &:not(:disabled):active {
    background-color: var(--grey-active-color);
  }
}

.bg-white {
  background-color: var(--white-color);

  &:not(:disabled):hover {
    background-color: var(--white-hover-color);
  }

  &:not(:disabled):active {
    background-color: var(--white-active-color);
  }
}

.color-white {
  color: var(--white-color);
}

.bg-disabled {
  background-color: var(--disabled-color);
}

.color-dark-secondary {
  color: var(--dark-secondary-color);
}

.color-dark-bg-secondary-text {
  color: var(--dark-bg-secondary-text);
}

.bg-secondary {
  background-color: var(--secondary-color);

  &:not(:disabled):hover {
    background-color: var(--secondary-hover-color);
  }

  &:not(:disabled):active {
    background-color: var(--secondary-active-color);
  }
}

.color-alert {
  color: var(--alert-color);
}

.bg-alert {
  background-color: var(--alert-color);

  &:not(:disabled):hover {
    background-color: var(--alert-hover-color);
  }

  &:not(:disabled):active {
    background-color: var(--alert-active-color);
  }
}

.bg-alert-background {
  background-color: var(--alert-background);
}

.bg-warning-background {
  background-color: var(--warning-background);
}

.bg-btn-active {
  background-color: var(--btn-active-color);

  &:not(:disabled):hover {
    background-color: var(--btn-active-color-hover);
  }

  &:not(:disabled):active {
    background-color: var(--btn-active-color-pressed);
  }
}

.color-warning {
  color: var(--warning-color);
}

.color-blue {
  color: var(--blue-color);
}

.bg-blue {
  background-color: var(--blue-color);
}

.bg-green {
  background-color: var(--green-color);
}

.bg-purple {
  background-color: var(--purple-color);
}

.bg-orange {
  background-color: var(--orange-color);
}

.square-btn-28 {
  height: 28px;
  width: 28px;
}

.square-btn-36{
  height: 36px;
  width: 36px;
}


.project__header-start{
  display: flex;
  align-items: center;
  gap: 20px;
}
.back-icon{
  cursor: pointer;
}
.project__header-title{
  color: var(--white-color)
}

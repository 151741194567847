.region-coefficients-table__container {
  .grid-table__header:nth-child(1){
    display: flex;
    align-items: center;
    grid-row-start: 1;
    grid-row-end: 3;
  }
  .grid-table__header:nth-child(2) {
    grid-column-start: 2;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .grid-table__header:nth-child(3) {
    display: flex;
    align-items: center;
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: 5;
    grid-column-end: 5;
  }

  &.edit {
    .grid-table__header:nth-child(1){
      display: flex;
      align-items: center;
      grid-row-start: 1;
      grid-row-end: 3;
      grid-column-start: 1;
      grid-column-end: 1;
    }
    .grid-table__header:nth-child(2){
      display: flex;
      align-items: center;
      grid-row-start: 1;
      grid-row-end: 3;
      grid-column-start: 2;
      grid-column-end: 2;
    }
    .grid-table__header:nth-child(3) {
      grid-column-start: 3;
      grid-column-end: 6;
      grid-row-start: 1;
      grid-row-end: 1;

    }

    .grid-table__header:nth-child(4) {
      display: flex;
      align-items: center;
      grid-row-start: 1;
      grid-row-end: 3;
      grid-column-start: 6;
      grid-column-end: 6;
    }

    .grid-table__header:nth-child(5) {
      display: flex;
      align-items: center;
      grid-row-start: 2;
      grid-row-end: 2;
      grid-column-start: 3;
      grid-column-end: 3;
    }

    .grid-table__header:nth-child(6) {
      display: flex;
      align-items: center;
      grid-row-start: 2;
      grid-row-end: 2;
      grid-column-start: 4;
      grid-column-end: 4;
    }

    .grid-table__header:nth-child(7) {
      display: flex;
      align-items: center;
      grid-row-start: 2;
      grid-row-end: 2;
      grid-column-start: 5;
      grid-column-end: 5;
    }
  }
}


.project-page{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.project-page__body{
  display: flex;
  flex-direction: row;
  flex: 1 1 0;
  //max-height: calc(100% - 64px);
}

.toolbar{
  //height: calc(100vh - 64px);
  width: 52px;
  background: var(--grey-color);
}
.toolbar-btn {
  border: none;
  padding: 16px;
  background-color:  var(--grey-color);
  cursor: pointer;
  & svg path {
    stroke: #6BA0C1;
  }
  & svg circle:not(:last-child) {
    fill: #6BA0C1;
  }
  &.active svg path {
    stroke: var(--primary-color);
  }
  &.active svg circle:not(:last-child) {
    fill: var(--primary-color);
  }
  & .auto-cs-icon path,
  & .optimization-icon path {
    fill: #6BA0C1;
  }
  &.active .auto-cs-icon path,
  &.active .optimization-icon path{
    fill: var(--primary-color);
  }
  & svg circle:last-child {
    fill: transparent;
  }
  & svg.done circle:last-child {
    fill: var(--green-color);
  }
  & svg.error circle:last-child {
    fill: var(--alert-color);
  }
  & svg.warning circle:last-child {
    fill: var(--warning-color);
  }
  &:not(:disabled).active{
    background-color: var(--white-color);
  }
  &:disabled{
    & svg path {
      stroke: var(--dark-secondary-color);
    }
    cursor: default;
  }
}

.model10__drop-container {
  margin: 32px 0;
  display: flex;
  gap: 20px;
  width: 100%;
  height: 95px;
}

.model10__calculation-btn {
  width: 192px;
}

.file-dropzone__filename {
  max-width: 70%;
  margin: 0 12px 0 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--secondary-text-color);
}

.file-dropzone__close-icn {
  & path{
    stroke: var(--secondary-text-color);
  }
}

.file-dropzone__close-btn {
  border-radius: 4px;

  &:not(:disabled) {
    cursor: pointer;
  }

  &:hover {
    background: var(--white-hover-color);
  }

  &:active {
    background: var(--white-active-color);
  }
}

.progress__loader-container {
  display: flex;
  gap: 8px;

  &.model10-loader-container {
    margin: 32px 0 12px 0;
  }
}

.progress__loader {
  width: 40px;
  height: 40px;
  background-image: url(../../../images/gpp_loader.gif);
  background-size: contain;
  background-repeat: no-repeat;
}

.progress__percentage {
  align-self: flex-end;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: var(--primary-color);
}

.progress__hint {
  color: var(--secondary-text-color);
  margin: 12px 0;
}

.main-button.primary.results__btn {
  width: 220px;
  margin-top: 32px;

  & .main-button__icon-wrapper svg path {
    fill: none;
  }

  &:disabled .main-button__icon-wrapper svg path {
    stroke: var(--secondary-text-color);
  }
}

.results__top-row {
  display: flex;
  justify-content: space-between;
}

.results__status-wrapper {
  display: flex;
  gap: 8px;
  align-items: center;
}

.results__error {
  max-height: 400px;
  margin-top: 20px;
  color: var(--secondary-text-color);
  overflow: auto;
}

.progress__stop-task-btn {
  margin-top: 20px;
  width: 127px;
}



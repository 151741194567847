.hydraulic-results__tabs {
  display: flex;
  gap: 8px;
}

.hydraulic-results__tab {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: var(--primary-text-color);
  padding: 8px 16px;
  border-radius: 30px;
  background-color: transparent;
  cursor: pointer;

  &.active {
    background-color: var(--primary-color);
    color: var(--white-color);
  }
}

.results__under-chart-container {
  margin-top: 20px;
  //width: fit-content;
  max-width: 100%;
  width: 100%;
}

.results__chart-container {
  position: relative;
  margin-top: 20px;
  width: 100%;
  height: 47.87vh;
}

.hydraulic-results__stations-tabs {
  display: flex;
  margin-bottom: 20px;
}

.hydraulic-results__stations-tab {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 17.57px;
  cursor: pointer;
  color: var(--text-color-3);
  border-bottom: 2px solid var(--text-color-3);
  height: 42px;
  padding: 0 24px;
  white-space: nowrap;
  &:first-child {
    min-width: 57px;
    width: max-content;
  }

  &:last-child {
    width: calc(100% - 57px);
  }

  &.active {
    color: var(--primary-color);
    border-bottom-color: var(--primary-color);
  }
}

.result-table {
  .grid-table__header {
    min-width: 128px;
  }
}

.rows-table__container {
  & .grid-table__header {
    &:before {
      display: none;
    }
  }
}

.blocking-window-background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 200;
  background-color: rgba(0, 0, 0, 0.16);
}

.blocking-window-container {
  position: absolute;
  display: flex;
  min-width: 410px;
  flex-direction: column;
  gap: 24px;
  top: 50%;
  left: 50%;
  //width: 480px;
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.04);
  transform: translate(-50%, -50%);

  &.stop-task-confirm {
    width: 410px;
  }

  &.confirm-window {
    width: 410px;
  }
  &.export-window {
    width: 410px;
  }
  &.gpu-choices-window {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  min-width: 905px;
}
}
.blocking-window__header{
  display: flex;
  justify-content: space-between;
  font-weight: 800;
  font-size: 14px;
  line-height: 18px;
  color: var(--primary-text-color);
}

.font-size-12{
  font-size: 12px;
}

.blocking-window__body{
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: var(--secondary-text-color);

  &.blocking-window__confirm-body {
    line-height: 170%;
  }

  &.blocking-window__body-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}

.blocking-window__url {
  display: flex;
  width: 301px;
  gap: 12px;
}
.blocking-window__url-text{
  word-break: break-all;
}
.main-button.blocking-window__copy {
    padding: 0;
    max-width: 20px;
    max-height: 20px;
  & .main-button__icon-wrapper {
    & svg path {
      fill: none;
      stroke: var(--secondary-text-color);
    }
  }
}
.blocking-window__footer {
  display: flex;
  justify-content: flex-end;
  gap: 4px;
  margin-top: auto;
}

.choices-table {
  max-width: calc(100% - 80px);
  max-height: calc(100% - 80px);
}

.gap-16{
  gap: 16px;
}
.gap-8{
  gap: 8px;
}
.confirm-window__list {
  padding-inline-start: 24px;
  margin-block-start: 0;
  margin-block-end: 0;
}


.gpu-table__container {
  .grid-table {
    grid-template-columns: minmax(max-content, max-content) minmax(168px, auto) minmax(112px, auto) minmax(132px, auto) minmax(132px, auto) minmax(112px, auto) minmax(120px, auto) minmax(112px, auto) minmax(170px, auto) minmax(170px, auto) minmax(132px, auto) minmax(120px, auto) minmax(140px, auto) minmax(140px, auto) minmax(140px, auto) minmax(140px, auto) minmax(140px, auto) minmax(140px, auto) minmax(140px, auto) minmax(140px, auto) minmax(140px, auto) minmax(140px, auto) minmax(140px, auto) minmax(140px, auto) minmax(140px, auto) minmax(140px, auto) minmax(132px, auto) minmax(132px, auto) minmax(170px, auto) minmax(120px, auto) minmax(170px, auto) minmax(120px, auto) !important;
  }

  &.edit {
    .grid-table {
      grid-template-columns: 48px minmax(max-content, max-content) minmax(168px, auto) minmax(112px, auto) minmax(132px, auto) minmax(132px, auto) minmax(112px, auto) minmax(120px, auto) minmax(112px, auto) minmax(170px, auto) minmax(170px, auto) minmax(132px, auto) minmax(120px, auto) minmax(140px, auto) minmax(140px, auto) minmax(140px, auto) minmax(140px, auto) minmax(140px, auto) minmax(140px, auto) minmax(140px, auto) minmax(140px, auto) minmax(140px, auto) minmax(140px, auto) minmax(140px, auto) minmax(140px, auto) minmax(140px, auto) minmax(140px, auto) minmax(132px, auto) minmax(132px, auto) minmax(170px, auto) minmax(120px, auto) minmax(170px, auto) minmax(120px, auto) !important;
    }
  }
}

.gpu-table__container.stripped_down.edit {
  .grid-table {
    grid-template-columns:
            48px minmax(max-content, max-content)
            minmax(168px, auto)
            minmax(112px, auto)
            minmax(132px, auto)
            minmax(132px, auto)
            minmax(112px, auto)
            minmax(120px, auto)
            minmax(112px, auto)
            minmax(170px, auto)
            minmax(170px, auto)
            minmax(140px, auto)
            minmax(132px, auto)
            minmax(132px, auto)
            minmax(120px, auto) !important;
  }
}

.gpu-table__container.stripped_down {
  .grid-table {
    grid-template-columns:
            minmax(max-content, max-content)
            minmax(168px, auto)
            minmax(112px, auto)
            minmax(132px, auto)
            minmax(132px, auto)
            minmax(112px, auto)
            minmax(120px, auto)
            minmax(112px, auto)
            minmax(170px, auto)
            minmax(170px, auto)
            minmax(140px, auto)
            minmax(132px, auto)
            minmax(132px, auto)
            minmax(120px, auto) !important;
  }
}

.directory-filters__container {
  width: 153px;
  margin-bottom: 20px;
}

.readonly {
  pointer-events: none;

  .input {
    color: var(--secondary-text-color)
  }
}

.gpu-table__container {
  max-height: calc(100vh - 64px - 81px - 20px - 20px - 73px);
}

.task__title-column {
  display: flex;
  gap: 4px;
}

.task__title-wrapper {
  max-width: calc(100% - 20px - 4px);
}

.task__title {
  overflow: hidden;
  word-break: normal;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  max-width: 100%;
  color: var(--primary-text-color);
}

.task__project-wrapper {
  max-width: 100%;
}
.task__status-wrapper {
  //height: 17px;
}

.task__secondary-text {
  color: var(--secondary-text-color);
}

.task__second-row {
  margin-top: 14px;
}

$column-count: 5;
$gutter: 20px;

@mixin first-row {
  &:nth-child(-n + #{$column-count}) {
    @content;
  }
}

@mixin last-row {
  &:nth-last-child(-n + #{$column-count}) {
    @content;
  }
}

@mixin first-column {
  &:nth-child(#{$column-count}n + 1) {
    @content;
  }
}

@mixin last-column {
  &:nth-child(#{$column-count}n) {
    @content;
  }
}

.tasks__table-item {
  padding: $gutter + 10 $gutter $gutter + 8 0;
  border-bottom: 1px solid var(--border-color);

  @include first-row {
    padding-top: 0;
  }

  @include last-row {
    border-bottom: none;
  }

  @include last-column {
    padding-right: 0;
    padding-top: $gutter;
  }
}

.tasks__table-item-title-icon {
  margin-top: -3px;
}

.task__idle-stop {
  display: flex;
  height: 36px;
  align-items: center;
}

.tasks__table-status {
  // width: 100%;
}

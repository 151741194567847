.area-laying-categories-table__container {
  max-width: 828px;

  .grid-table {
    grid-template-columns: 207px repeat(3, auto) !important;
  }

  .grid-table__header:nth-child(1) {
    position: sticky;
    top: 0;
    grid-row-start: span 2;
  }

  .grid-table__header:nth-child(2) {
    position: sticky;
    top: 0 !important;
    grid-column-start: span 3;
  }
}

.area-laying-categories-table__header {
  background: var(--grey-color) !important;
  color: var(--primary-text-color) !important;
  font-weight: 800;
  font-size: 12px;
  line-height: 15px;
}
.directory-card__flex-start {
  flex-grow: 1;
  max-width: calc(100% - 100px);
}

.directory-card__flex-end {
  width: 120px;

  & .project-owner {
    max-width: 0;
    min-width: 0;
  }
}

.directory-title {
  flex-grow: 1;
  max-width: calc(100% - 30px);
}

.pipeline-result {
  margin-top: 20px;
  max-height: calc(100vh - 124px - 64px - 50px);

  .expand-cell {
    display: flex;
    grid-column-start: 1;
    grid-column-end: 12;
    justify-content: flex-start;
    padding-left: 10px;
    gap: 26px;
  }

  .expand-cell__title {
    color: var(--secondary-text-color);
    text-transform: uppercase;
  }
}

.pipeline-result__cs-expand {
  display: flex;
  gap: 32px;
  padding: 24px 20px;
}

.pipeline-result__cs-expand__column {
  display: flex;
  width: 152px;
  flex-direction: column;
  gap: 24px;
  border-right: 1px solid var(--border-color);

  &:last-child {
    border: none;
  }
}

.pipeline-result__cs-expand__row {
  height: 40px;

  & > .title {
    color: var(--secondary-text-color);
    margin-bottom: 10px;
  }
}

.hydraulic-results__stations_expand-row {
  display: flex;
  flex-direction: column;
}

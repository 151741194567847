.optimization-info__error-message {
  color: var(--secondary-text-color);
  font-weight: 600;
  font-size: 12px;
}

.optimization-results__status {
  margin-left: 8px;
}

.optimization-results__edit-element-panel {
  position: relative;
  left: unset;
  width: 420px;
  max-height: calc(100vh - 64px - 76px);
}

.optimization-results__title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

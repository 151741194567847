.gas-transport-scheme__frame{
  width: 100%;
  height: calc(100% - 64px);
  position: absolute;
  top: 64px;
  z-index: 15;
  background: var(--white-color);
}
.gas-transport-scheme__header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px;
  background: var(--background-color-1);
  & > div:first-child{
    display: flex;
    align-items: center;
    gap: 20px;
    button {
      padding: 8px;
    }
  }
}
.acu-table__container {
  .grid-table {
    grid-template-columns: minmax(max-content, max-content) minmax(100px, auto) minmax(112px, auto) minmax(140px, auto) minmax(140px, auto) minmax(120px, auto) minmax(132px, auto) minmax(120px, auto) minmax(120px, auto) minmax(112px, auto) minmax(120px, auto) minmax(100px, auto) minmax(100px, auto) minmax(156px, auto) minmax(156px, auto) minmax(180px, auto) minmax(100px, auto) minmax(170px, auto) minmax(170px, auto) minmax(156px, auto) !important;
  }
  &.edit {
    .grid-table {
      grid-template-columns: 48px minmax(max-content, max-content) minmax(100px, auto) minmax(112px, auto) minmax(140px, auto) minmax(140px, auto) minmax(120px, auto) minmax(132px, auto) minmax(120px, auto) minmax(120px, auto) minmax(112px, auto) minmax(120px, auto) minmax(100px, auto) minmax(100px, auto) minmax(156px, auto) minmax(156px, auto) minmax(180px, auto) minmax(100px, auto) minmax(170px, auto) minmax(170px, auto) minmax(156px, auto) !important;
    }
  }
}

.height-profile-control {
  position: absolute;
  top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
  right: calc(60px + 116px + 20px + 100px + 40px);
  /*&.edit{
    top: 17px;
    right: calc(60px + 116px + 20px + 100px + 40px - 3px);
  }*/
}

.height-profile-control__btn {
    padding: 8px 12px 8px 8px;
}

.kebab-button__container {
  position: relative;
  display: flex;
  align-items: center;
}

.kebab-button {
  background: transparent;
  border: none;
  display: flex;
  cursor: pointer;
  justify-content: center;
  border-radius: 4px;

   font-weight: 700;
   font-size: 12px;
   line-height: 15px;

  &.light,
  &.light-btn {
    width: 20px;
    height: 20px;
    padding: 0;

    &:not(:disabled):focus, &:not(:disabled):hover, &:not(:disabled):active {
      background-color: var(--white-hover-color)
    }

    &.visible {
      background-color: var(--white-active-color);
    }

    .kebab-img > path {
      fill: var(--secondary-text-color);
    }
  }

  &.dark {
    gap: 4px;
    align-items: center;
    background-color: var(--primary-light-color);
    padding: 8px 8px 8px 12px;
    color: var(--white-color);

    &:focus, &:hover {
      background-color: var(--primary-light-hover-color);
    }

    &:active {
    background-color:  var(--primary-light-active-color);
    }

    &.visible {
      background-color: var(--primary-light-selected-color);
    }

    .kebab-img > path {
      stroke: var(--white-color);
    }
  }

  &:disabled {
    cursor: default;
  }

}


.popup-menu__container {
  position: absolute;
  right: 0;
  top: 26px;
  display: flex;
  flex-direction: column;
  min-height: 36px;
  background: var(--border-color);
  border: 1px solid #EEF0F4;
  border-radius: 4px;
  z-index: 20;

  &.dark {
    top: 42px;
  }

  &.light-btn {
    top: 34px;
  }

  &.hidden {
    display: none;
  }

  &.visible {
    display: flex;
    gap: 1px;
    flex-direction: column;
  }

  & > button {
    padding: 8px 12px;
    border-radius: 0;
  }

  & > button:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  & > button:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

